<template>
  <div>
    <div class="footer">
      <div class="icp fink">
        <p class="agreement-list">
          <a target="_blank" href="https://cdn-game.paquhuyu.com/platform/agreement/yinsi.html">隐私协议</a>
          <a target="_blank" href="https://cdn-game.paquhuyu.com/platform/agreement/userscon.html">用户协议</a>
          <a target="_blank" href="index.html#contact">联系我们</a>
          <a target="_blank" href="https://cdn-game.paquhuyu.com/platform/agreement/ertongyinsi.html">儿童协议</a>
          <a target="_blank" href="https://cdn-game.paquhuyu.com/platform/agreement/zilv.html">自律公约</a>
        </p>
        <p>健康游戏忠告：抵制不良游戏， 拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活</p>
        <p>
          <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052358">
            <img src="https://cdn-game.paquhuyu.com/website/static/others/beian.png" alt=""/>
            京公网安备 11010502052358号
          </a>
          <a target="_blank" href="https://beian.miit.gov.cn"> 京ICP备18027878号-1 </a>
          <a target="_blank" href="https://cdn-game.paquhuyu.com/dream/third/img/北京葩趣互娱科技有限公司.jpg"> 营业执照 </a>
          <a target="_blank"> ISBN 978-7-498-05678-8 </a>
          <a target="_blank">国新出审[2019]83号</a>
        </p>
        <p>用户投诉举报通道:邮箱 gmservice@popmart.com 丨电话:4000232233</p>
        <p>涉未成年人专用邮箱:gmservice@popmart.com</p>
        <p>COPYRIGHT © 葩趣互娱 PAQUHUYU.COM ALL RIGHTS RESERVED</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped>

</style>