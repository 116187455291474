module.exports = {
  env: process.env.NODE_ENV,
  development: {
    aesKey: "",
    aesIV: "",
    secretKey: "",
    encryptEnabled: false
  },
  test: {
    aesKey: "",
    aesIV: "",
    secretKey: "",
    encryptEnabled: false
  },
  production: {
    aesKey: "",
    aesIv: "",
    secretKey: "",
    encryptEnabled: false
  },
  getAesKey() {
    return this[this.env].aesKey;
  },
  getAesIv() {
    return this[this.env].aesIV;
  },
  getSecretKey() {
    return this[this.env].secretKey;
  },
  isEncryptEnabled() {
    return this[this.env].encryptEnabled;
  }
};