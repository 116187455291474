module.exports = {
  "1001": {
    "title": "《梦想家园》手游适龄公告",
    "image": "https://cdn-game.paquhuyu.com/website/static/others/news_dimoo.jpg",
    "time": "2023-05-08",
    "signature": "《梦想家园》",
    "content": "<p>1.本游戏是一款玩法简单的模拟经营养成游戏，适用于年满12周岁以上的用户，建议未成年人在家长监护下使用游戏产品。</p><p>2.本游戏基于架空世界为故事背景，剧情讲述了玩家在睡眠中进入奇妙的梦境，在梦境中遇到了很多有趣诙谐的朋友，并一起建设云鲸岛的故事。故事轻松愉快且积极向上，画面梦幻可爱、配乐轻松明快。游戏中需要用户经营建造梦境中的云鲸岛，较为休闲益智。游戏中有基于文字的陌生人社交系统，但社交系统的管理遵循相关法律法规。</p><p>3.本游戏中有用户实名认证系统，未实名的用户无法登录并进入游戏，认证为未成年人的用户将接受以下管理：游戏中部分玩法和道具需要付费，未满8周岁的用户不能付费；8周岁以上的未满16周岁的未成年人用户，单次充值金额不得超过50元人民币，每月充值金额累计不得超过200元人民币；16周岁以上的未成年人用户，单次充值金额不得超过100元人民币，每月充值金额累计不得超过400元人民币。未成年人用户仅可在周五、周六、周日和法定节假日每日20时至21时进行游玩。<p>4.游戏中包含种植、钓鱼、做菜等趣味小游戏，能够培养玩家日常的兴趣爱好。能够激起用户对传统手工以及田园的兴趣，增强传统文化认同感。</p>"
  },
  "1002": {
    "title": "古灵精怪的LABUBU",
    "image": "https://cdn-game.paquhuyu.com/website/static/others/news_labubu_1.jpg",
    "time": "2023-05-08",
    "signature": "《梦想家园》",
    "content": "<p style=\"text-align: center\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/labubu_1.jpg\" style=\"width: 100%\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/labubu_2.jpg\" style=\"width: 100%\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/labubu_3.jpg\" style=\"width: 100%\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/labubu_4.jpg\" style=\"width: 100%\">\n" +
      "          </p>\n" +
      "          <h3><strong>了解更多</strong></h3><p>官方微博：@梦想家园手游</p><p>官方公众号：梦想家园手游</p><p>官方玩家1群：724652978（已满）</p><p>官方玩家2群：597862809（已满）</p><p>官方玩家3群：770694946</p>"
  },
  "1003": {
    "title": "无所畏惧的MOLLY公主",
    "image": "https://cdn-game.paquhuyu.com/website/static/others/news_molly_1.jpg",
    "time": "2023-05-08",
    "signature": "《梦想家园》",
    "content": "<blockquote><p><strong>遇见日常中的好奇心角落 Inspire Curiosity at Every Corner of Life</strong></p></blockquote>\n" +
      "          <p style=\"text-align: center\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/molly_1.jpg\" style=\"width: 100%\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/molly_2.jpg\" style=\"width: 100%\">\n" +
      "            <img src=\"https://cdn-game.paquhuyu.com/website/static/others/molly_3.jpg\" style=\"width: 100%\">\n" +
      "          </p>\n" +
      "          <h3><strong>了解更多</strong></h3><p>官方微博：@梦想家园手游</p><p>官方公众号：梦想家园手游</p><p>官方玩家1群：724652978（已满）</p><p>官方玩家2群：597862809（已满）</p><p>官方玩家3群：770694946</p>"
  },
};