import CryptoJS from 'crypto-js/crypto-js'
import settings from "@/common/settings";

export function  hmacSha256(data) {
  return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(data, settings.getSecretKey()));
}

export function aesEncrypt(data) {
  const keyBytes = CryptoJS.enc.Utf8.parse(settings.getAesKey());
  const ivBytes = CryptoJS.enc.Utf8.parse(settings.getAesIv());
  let src = CryptoJS.enc.Utf8.parse(data);
  // 加密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
  let encrypted = CryptoJS.AES.encrypt(src, keyBytes, {
    iv: ivBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  //返回base64
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

export function aesDecrypt(data) {
  const keyBytes = CryptoJS.enc.Utf8.parse(settings.getSecretKey());
  const ivBytes = CryptoJS.enc.Utf8.parse(settings.getAesIv());
  let src = CryptoJS.enc.Base64.parse(data);
  // 解密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
  let decrypt = CryptoJS.AES.decrypt(src, keyBytes, {
    iv: ivBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}