<template>
  <van-overlay :show="show">
    <div class="loading-wrapper">
      <van-loading type="spinner" color="#0ec160" :vertical="true" size="50">处理中...</van-loading>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "Loading.vue",
  data() {
    return {
      show: false,
    }
  }
}
</script>
<style scoped>
</style>