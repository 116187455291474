<template>
  <div>
    <header id="topHeader">
      <div class="container-header" ref="mainHeader">
        <div class="logo">
          <a :href="'index.html'">
            <img src="@/assets/images/pc/wangye_zhiding_2.png" alt="logo" class="logo-img">
          </a>
        </div>
        <div class="nav-list">
          <div class="row align-items-center">
            <div class="col-lg-12 d-none d-lg-block">
              <div class="main-menu">
                <nav id="nav_mobile_menu">
                  <ul id="navigation">
                    <li v-for="(naviInfo, index) of naviList" :key="naviInfo.tag" :id="'navi-'+ naviInfo.tag">
                      <a :href="links.dream" class="navi-item" :class="naviIndex === index ? 'active-navi-item' : ''">{{naviInfo.name}}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
<!--        <div style="" class="head-login-div">-->
<!--          <a class="head-login-bt" @click="showLoginDialog" v-if="!account">立即预约</a>-->
<!--          <span v-if="account" style="color: white">{{account}}</span>-->
<!--          <a class="head-login-bt" @click="logout" v-if="account">注销</a>-->
<!--        </div>-->
      </div>
    </header>
    <van-dialog v-model="loginDialog" :show-confirm-button="false" :closeOnClickOverlay="true"
                class="login-dialog" overlay-class="dialog-overlay" :lock-scroll="false">
      <div class="pop-div">
        <div class="pop-div-title">短信登录</div>
        <img class="pop-div-close" src="@/assets/images/mobile/wangye_shiling_guanbi.png" alt="close" @click="closeLoginDialog">
        <div class="pop-div-content-login">
          <Row style="margin-top: -10px">
            <Col span="24" style="text-align: center;">
              <img src="@/assets/images/icon/Logo3.png" alt="logo" style="width: 70%;">
            </Col>
          </Row>
          <van-form @submit="login">
            <van-cell-group>
              <van-field
                  v-model="phoneNumber"
                  type="tel"
                  clearable
                  maxlength="11"
                  placeholder="请输入手机号"
                  style="font-size: medium"
              />
              <van-field
                  v-model="captcha"
                  clearable
                  maxlength="4"
                  placeholder="请输入图片验证码"
                  style="font-size: medium"
              >
                <template #button>
                  <img :src="captchaImage" alt="图片验证码" @click="changeCaptcha" style="cursor: pointer; width: 110px">
                </template>
              </van-field>
              <van-field
                  v-model="thirdAccessToken"
                  clearable
                  type="number"
                  maxlength="6"
                  placeholder="请输入短信验证码"
                  style="font-size: medium"
              >
                <template #button>
                  <span style="margin: 0 5px;" v-if="leftTime > 0" >{{ leftTime}}秒</span>
                  <a class="login-dialog-sm-btn" @click="sendSM" v-if="leftTime === 0">发送验证码</a>
<!--                  <Button type="primary" style="margin: 0 5px; width: 110px" @click="sendSM" class="c-span12" v-if="leftTime === 0">发送验证码</Button>-->
                </template>
              </van-field>
            </van-cell-group>
            <Row style="padding: 10px 16px;">
              <a class="login-dialog-login-btn" @click="login">登录</a>
<!--              <van-button block type="info" native-type="submit" class="login-dialog-login-btn">登录</van-button>-->
            </Row>
          </van-form>
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model="infoDialog" :show-confirm-button="false" :closeOnClickOverlay="true" :lock-scroll="false"
                class="info-dialog" overlay-class="dialog-overlay">
      <div class="pop-div">
        <div class="pop-div-title">您已预约成功</div>
        <img class="pop-div-close" src="@/assets/images/mobile/wangye_shiling_guanbi.png" alt="close" @click="closeInfoDialog">
        <div class="pop-div-content" style="text-align: center ">
          <span>感谢您的预约，Molly即将带您启程，敬请期待！</span>
        </div>
      </div>
    </van-dialog>
    <a class="scroll_to_top" href="#topHeader" ref="scrollToTop">
      <van-icon name="arrow-up"/>
    </a>
  </div>
</template>

<script>
import settings from "@/common/settings";
import {aesEncrypt} from "@/common/encryptUtil";
import {getHeadersWithSign} from "@/common/utils";
import {nanoid} from "nanoid";
import linksList from "@/common/links";

export default {
  name: "MainHeader",
  data() {
    return {
      subscribeCount: 0,
      phoneNumber: null,
      thirdAccessToken: null,
      subscribed: false,
      loginDialog: false,
      captcha: null,
      captchaImage: null,
      captchaId: null,
      account: null,
      leftTime: 0,
      ticker: null,
      scrollTop: 0,
      naviList: [{
        name: '首页',
        tag: 'home'
      }, {
        name: '绘梦惊喜',
        tag: 'news'
      }, {
        name: '预约有礼',
        tag: 'art'
      }, {
        name: '新闻资讯',
        tag: 'archives'
      }, {
        name: '角色介绍',
        tag: 'roles'
      }, {
        name: '玩法介绍',
        tag: 'games'
      }, {
        name: '关注我们',
        tag: 'contact'
      }],
      infoDialog: false,
      naviIndex: 0,
      processing: false,
      lastTime: 0,
      links: linksList
    }
  },
  mounted() {
    let self = this;
    this.captchaId = nanoid();
    localStorage.setItem('appId', 'popEf2Lfo9x0nXudDua');
    localStorage.setItem('deviceId', '123');
    let userInfoStr = localStorage.getItem("userInfo");
    if (userInfoStr) {
      let userInfo = JSON.parse(userInfoStr);
      self.subscribed = !!userInfo.subscribed;
      self.account = userInfo.phoneNumber;
      this.emitUserInfoChange();
    }
    window.addEventListener('scroll', this.handleScroll, true);
    this.handleScroll();
  },
  methods: {
    async showLoginDialog() {
      await this.changeCaptcha();
      this.loginDialog = true;
    },
    closeLoginDialog() {
      this.loginDialog = false;
    },
    async login() {
      let self = this;
      if (!self.phoneNumber) {
        self.$toast({
          message: "请输入手机号码",
          duration: 1000
        });
        return;
      }
      if (self.phoneNumber.length !== 11 || !/^\d{11}$/.test(self.phoneNumber)) {
        self.$toast({
          message: "手机号码格式错误，请重新输入",
          duration: 1000
        });
        return;
      }
      if (!self.thirdAccessToken) {
        self.$toast({
          message: "请输入验证码",
          duration: 1000
        });
        return;
      }
      if (self.thirdAccessToken.length !== 6 || !/^\d{6}$/.test(self.thirdAccessToken)) {
        self.$toast({
          message: "验证码格式错误，请重新输入",
          duration: 1000
        });
        return;
      }
      let params = {
        appId: localStorage.getItem("appId"),
        loginType: 'sms',
        platform: 0,
        thirdOpenId: self.phoneNumber,
        thirdAccessToken: self.thirdAccessToken,
        from: "web",
        channel: localStorage.getItem("channel") || '',
      }
      let body = null;
      if (settings.isEncryptEnabled()) {
        body = aesEncrypt(JSON.stringify(params));
        params = {
          'data': body
        }
      }
      let headers = getHeadersWithSign(
          localStorage.getItem("deviceId"),
          null,
          null,
          null,
          body
      );
      if (self.processing || Date.now() - self.lastTime < 1000) {
        return;
      }
      self.processing = true;
      self.lastTime = Date.now();
      try {
        await self.$ajax.post('/v1/auth/subscribe/add', params, {headers}).then(res => {
          if (!res || !res.data || res.data.code !== 0) {
            let msg = "登录失败";
            let code = res?.data?.code || -1;
            if (code === 1002) {
              msg = "手机号不正确";
            } else if (code === 1003) {
              msg = "短信验证码不正确或已过期";
            }
            self.$toast({
              message: msg,
              duration: 1000
            });
            return;
          }
          let userInfo =  res.data.data;
          self.subscribed = !!userInfo.subscribed;
          self.account = userInfo.phoneNumber;
          self.emitUserInfoChange();
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('openId', userInfo.openId);
          localStorage.setItem('accessToken', userInfo['accessToken']);
          self.loginDialog = false;
          // self.$toast({
          //   message: "登录成功",
          //   duration: 1000
          // });
          self.showHasSubscribed();
          self.leftTime = 0;
          clearTimeout(self.ticker);
        }).catch(error => {
          console.log(`[login] failed, error:`, error)
        });
      } catch (error) {
        console.log(`[login] error:`, error)
      } finally {
        self.processing = false;
      }
    },
    async logout() {
      let self = this;
      localStorage.removeItem('userInfo');
      self.subscribed = false;
      self.account = null;
      self.phoneNumber = null;
      self.captcha = null;
      self.thirdAccessToken = null;
      self.emitUserInfoChange();
    },
    async sendSM() {
      let self = this;
      if (!self.phoneNumber) {
        self.$toast({
          message: "请输入手机号码",
          duration: 1000
        });
        return;
      }
      if (self.phoneNumber.length !== 11 || !/^\d{11}$/.test(self.phoneNumber)) {
        self.$toast({
          message: "手机号码格式错误，请重新输入",
          duration: 1000
        });
        return;
      }
      if (!self.captcha) {
        self.$toast({
          message: "请输入图片验证码",
          duration: 1000
        });
        return;
      }
      if (self.captcha.length !== 4 || !/^[a-zA-Z\d]{4}$/.test(self.captcha)) {
        self.$toast({
          message: "图片验证码格式错误，请重新输入",
          duration: 1000
        });
        return;
      }
      let params = {
        appId: localStorage.getItem("appId"),
        phoneNumber: self.phoneNumber,
        captchaId: self.captchaId,
        captcha: self.captcha,
      }
      let body = null;
      if (settings.isEncryptEnabled()) {
        body = aesEncrypt(JSON.stringify(params));
        params = {
          'data': body
        }
      }
      let headers = getHeadersWithSign(
          localStorage.getItem("deviceId"),
          null,
          null,
          null,
          body
      );
      if (self.processing || Date.now() - self.lastTime < 1000) {
        return;
      }
      self.processing = true;
      self.lastTime = Date.now();
      try {
        await self.$ajax.post('/v1/auth/oauth/captcha-sm', params, {headers}).then(res => {
          if (!res || !res.data || res.data.code !== 0) {
            let msg = "发送失败";
            let code = res?.data?.code || -1;
            if (code === 1002) {
              msg = "手机号不正确";
            } else if (code === 1003) {
              msg = "图片验证码不正确";
            }
            self.$toast({
              message: msg,
              duration: 1000
            });
            return;
          }
          self.$toast({
            message: "发送成功",
            duration: 1000
          });
          self.leftTime = 30;
          self.ticker = setInterval(() => self.checkLeftTime(), 1000);
        }).catch(error => {
          console.log(`[sendSM] failed, error:`, error)
        });
      } catch (error) {
        console.log(`[sendSM] error:`, error)
      } finally {
        self.processing = false;
      }
    },
    async getSubscribeInfo() {
      let self = this;
      let params = {
        appId: localStorage.getItem("appId"),
        deviceId: localStorage.getItem("deviceId"),
      };
      let headers = getHeadersWithSign(
          localStorage.getItem("deviceId"),
          localStorage.getItem("openId"),
          localStorage.getItem("accessToken"),
          params
      );
      await self.$ajax.get('/v1/auth/subscribe/info', {params, headers}).then(res => {
        if (!res || !res.data || res.data.code !== 0) {
          self.$toast({
            message: "获取失败",
            duration: 1000
          });
          return;
        }
        self.subscribeCount = res.data.data || 0;
        self.emitUserInfoChange();
      }).catch(error => {
        console.log(`[getSubscribeInfo] failed, error:`, error)
      });
    },
    async subscribe() {
      let self = this;
      if (self.subscribed) {
        self.showHasSubscribed();
        return;
      }
      await self.logout();
      await self.showLoginDialog();
      // if (!self.account) {
      //   self.showLoginDialog();
      //   return;
      // }
      // let params = {
      //   channel: localStorage.getItem("channel") || '',
      // }
      // let headers = getHeadersWithSign(
      //     localStorage.getItem("deviceId"),
      //     localStorage.getItem("openId"),
      //     localStorage.getItem("accessToken"),
      // );
      // await self.$ajax.get('/v1/auth/subscribe/add', {params, headers}).then(res => {
      //   if (!res || !res.data || res.data.code !== 0) {
      //     self.$toast({
      //       message: "预约失败",
      //       duration: 1000
      //     });
      //     return;
      //   }
      //   self.subscribed = true;
      //   self.subscribeCount = res.data.data || 0;
      //   self.emitUserInfoChange();
      //   self.showHasSubscribed();
      // }).catch(error => {
      //   console.log(`[getSubscribeInfo] failed, error:`, error)
      // });
    },
    showHasSubscribed() {
      let self = this;
      self.subscribed = true;
      self.infoDialog = true;
      // self.$dialog.alert({
      //   title: '您已预约成功',
      //   message: '感谢您的预约，Molly即将带您启程，敬请期待！',
      //   closeOnClickOverlay: true,
      //   overlayClass: "dialog-overlay"
      // }).catch(err => {
      //   console.log(err);
      // });
    },
    closeInfoDialog() {
      this.infoDialog = false;
    },
    async changeCaptcha() {
      let self = this;
      await self.getCaptcha();
    },
    async getCaptcha() {
      let self = this;
      let params = {
        appId: localStorage.getItem("appId"),
        deviceId: localStorage.getItem("deviceId"),
        captchaId: self.captchaId,
      };
      let headers = getHeadersWithSign(
          localStorage.getItem("deviceId"),
          localStorage.getItem("openId"),
          localStorage.getItem("accessToken"),
          params
      );
      await self.$ajax.get('/v1/auth/oauth/captcha', {params, headers}).then(res => {
        if (!res || !res.data || res.data.code !== 0) {
          self.$toast({
            message: "获取图片验证码失败",
            duration: 1000
          });
          return;
        }
        let data = res.data.data;
        self.captchaImage = data.url;
        self.captchaExpiration = data.expiration;
      }).catch(error => {
        console.log(`[getCaptcha] failed, error:`, error)
      });
    },
    handleScroll() {
      let self = this;
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (!this.scrollTop) {
        return;
      }
      let scrollToTop = self.$refs['scrollToTop'];
      if (!scrollToTop) {
        return;
      }
      // let mainHeader = self.$refs['mainHeader'];
      // if (this.scrollTop > 100) {
      //   mainHeader?.classList?.add("sticky-menu");
      // } else {
      //   mainHeader?.classList?.remove("sticky-menu");
      // }
      if (this.scrollTop > 300) {
        scrollToTop.style.display = "block";
        scrollToTop.classList.add("animated", "fadeIn");
      } else {
        scrollToTop.style.display = "none";
        // scrollToTop?.classList?.remove("fadeIn");
        // scrollToTop.classList.add("animated", "fadeOut");
      }
      // this.reCalculatorNavi();
    },
    checkLeftTime() {
      if (this.leftTime > 0) {
        this.leftTime--;
      } else {
        clearTimeout(this.ticker);
      }
    },
    emitUserInfoChange() {
      this.$emit('userInfoChange', this.subscribed, this.subscribeCount);
    },
    reCalculatorNavi() {
      let self = this;
      let offsetList = [0];
      let elList = [];
      let sectionElList = [];
      for (let naviInfo of self.naviList) {
        const sectionEls = document.querySelectorAll(`#${naviInfo.tag}`);
        if (sectionEls && sectionEls[0]) {
          offsetList.push(sectionEls[0].offsetTop + sectionEls[0].clientHeight / 2);
          sectionElList.push(sectionEls[0]);
          const navEls = document.querySelectorAll(`#navi-${naviInfo.tag}`);
          elList.push(navEls[0]);
        }
      }
      if (offsetList.length > 1) {
        for (let i = 0; i < offsetList.length - 1; i++) {
          if (self.scrollTop >= offsetList[i] && self.scrollTop <= offsetList[i + 1]) {
            elList[i].classList.add("active");
            let titleEls = sectionElList[i].getElementsByClassName("title-div");
            if (titleEls && titleEls.length > 0) {
                titleEls[0].classList.add("animated", "fadeInLeft");
            }
            let contendEls = sectionElList[i].getElementsByClassName("content-div");
            if (contendEls && contendEls.length > 0) {
                contendEls[0].classList.add("animated", "fadeInRight");
            }
            this.naviIndex = i;
          } else {
            elList[i].classList.remove("active");
          }
        }
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style>
</style>