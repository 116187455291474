import {hmacSha256} from "@/common/encryptUtil";

/**
 * 将时间转化为指定格式的字符串
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 *
 * @param date
 * @param fmt
 * @return {void | string}
 */
export function dateFormat (date, fmt) {
	let o = {
		'M+': date.getMonth() + 1,                      //月份
		'd+': date.getDate(),                           //日
		'h+': date.getHours(),                          //小时
		'm+': date.getMinutes(),                        //分
		's+': date.getSeconds(),                        //秒
		'q+': Math.floor((date.getMonth() + 3) / 3), //季度
		'S': date.getMilliseconds(),                    //毫秒
		'Z': clientTimeZone(date)
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	for (let k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
		}
	}
	return fmt;
}

function clientTimeZone(date) {
	let munites = date.getTimezoneOffset();
	let hour = Math.floor(munites / 60);
	let munite = munites % 60;
	let prefix = '-';
	if (hour < 0 || munite < 0) {
		prefix = '+';
		hour = -hour;
		if (munite < 0) {
			munite = -munite;
		}
	}
	hour = hour + '';
	munite = munite + '';
	if (hour.length === 1) {
		hour = '0' + hour;
	}

	if (munite.length === 1) {
		munite = '0' + munite;
	}
	return prefix + hour + munite;
}

export function getHeadersWithSign (deviceId, openId, accessToken, queryParams, body) {
	let headers = getHeaders(deviceId, openId, accessToken);
	headers['sign'] = getSign(headers, queryParams, body);
	return headers;
}

export function getHeaders (deviceId, openId, accessToken) {
	let headers = {
		'Content-type': 'application/json; charset=utf-8',
		'device-id': deviceId,
		'timestamp': Date.now() + ""
	};
	if (openId && accessToken) {
		// headers['Cookie'] = `openId=${openId};accessToken=${accessToken}`;
	}
	return headers;
}

export function getSign (headers, queryParams, body) {
	let tmpString = "timestamp=" + headers['timestamp'];
	if (queryParams && Object.keys(queryParams).length > 0) {
		let keys = [];
		for (let k in queryParams) {
			if (queryParams[k] !== undefined && queryParams[k] !== '') {
				keys.push(k);
			}
		}
		keys.sort();
		for (let key of keys) {
			tmpString += "&" +key + "=" + queryParams[key];
		}
	}
	if (body) {
		tmpString += "&data=" +body;
	}
	return hmacSha256(tmpString);
}